<template>
  <div className="hello">
    <h1>Welcome</h1>
    <v-text-field label="Client ID" v-model="clientId"></v-text-field>
    <v-simple-table>
        <thead>
        <tr>
          <th class="text-left">
            Prop
          </th>
          <th class="text-left">
            Value
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, propertyName) in urlParams" :key="propertyName">
          <td>{{propertyName}}</td>
          <td>{{value}}</td>
        </tr>
        </tbody>
    </v-simple-table>
    <ul>
      <li v-for="(message,i) in messages" :key="i">
        {{ message }}
      </li>
    </ul>
    <v-btn @click="login">LogIn</v-btn>
    <v-btn @click="getToken">getToken</v-btn>
    <v-btn @click="getTokenXHR">getTokenXHR</v-btn>
  </div>
</template>

<script>
import jwtDecode from "jwt-decode";
import axios from "axios";
export default {
  name: 'HelloWorld',
  data() {
    return {
      clientId:'_f377d8af03c6514cc88f006cf04693c4',
      clientSecret:'_3794f4ce45a5bbeaaab0a27d21d0ef3d',
      messages: ['Initialized','Push the button to login'],
      urlParams:{
        code:'',
        state:'',
        token:''
      }
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.urlParams.code=urlParams.get('code');
    this.urlParams.state=urlParams.get('state');
    this.urlParams.token=urlParams.get('token');
    if (this.urlParams.code){
      console.log(jwtDecode(this.urlParams.code));
    }
  },
  methods: {
    login() {
      window.location.href = 'https://shib.idm.dev.umd.edu/shibboleth-idp/profile/oidc/authorize'
          + '?client_id='+this.clientId
          + '&scope=openid'
          + '&redirect_uri=https://oidctest.sdf.dev.umd.edu/'
          + '&state=123'
          + '&response_type=code'
    },
    getToken(){
      this.redirectPost('https://shib.idm.dev.umd.edu/shibboleth-idp/profile/oidc/token',{
        client_id:this.clientId,
        client_secret:this.clientSecret,
        code:this.urlParams.code,
        grant_type:'authorization_code',
        redirect_uri:'https://oidctest.sdf.dev.umd.edu/'
      });

    },
    getTokenXHR(){
      this.messages.push('Posting exchange code for token');
      axios.post('https://shib.idm.dev.umd.edu/shibboleth-idp/profile/oidc/token',{
        client_id:this.clientId,
        client_secret:this.clientSecret,
        code:this.urlParams.code,
        grant_type:'authorization_code',
        redirect_uri:'https://oidctest.sdf.dev.umd.edu/'
      }).then((response)=>{
        let idToken = jwtDecode(response.data.id_token);
        this.messages.push('id_token:'+idToken);
        this.messages.push('access_token:'+response.data.access_token);
      }).catch((e)=>{
        this.messages.push('Error posting exchange code for token'+e);
      })
    },
    redirectPost(url, data) {
      var form = document.createElement('form');
      document.body.appendChild(form);
      form.method = 'post';
      form.action = url;
      for (var name in data) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = data[name];
        form.appendChild(input);
      }
      form.submit();
    }
  }
}
</script>
<!--https://oidctest.sdf.dev.umd.edu/?code=AAdzZWNyZXQxQfTfhI4-eZlkQfXRhLw7fmx-YdP2JVph_3bwxg2Y-nJzW0RD0gk5FSLPsOHXC3Vrc0cKyu5gdozb536aXrnHSSQhvvgV6rrPND4sITqD_EUUiWqWoTjx4h1QM8gvuiBVW2pak5GHmr8asMj_qzOgU4SDgMHXOKXur8LrdThBMrlPOiKiO5KT1QJ720_i0gnFsvIe7xuO7NzVD1sWTDHKy4wjtIFy_8xKbdA-7YlZxtWqPnp69fvgcS44YXp1ugbbOPk914mtRBfyFqKY_cFrYCOgn2oNeERxLTOmElbbtOsbwvMev0DvewpTil_H8C0y0j_2JAn0yaJMQqQglk9aCDrqVYDOGZd4Dsv8NlNLr8rNkEgbqZYRRn0Q9bKyggavH3r3EPtjzkTVxUx_euWCe4RaOBzzBRDQ8RIjmQD4IcnL-49002xiS7VhQ23W2Gm8zrHFPk1ZL4lFXXBCWBM&state=123-->